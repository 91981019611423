var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_vm._m(0),_vm._m(1),_c('section',{staticClass:"section pt-0 px-0"},[_c('div',{staticClass:"container is-mobile",staticStyle:{"max-width":"810px"}},[_c('div',{staticClass:"content has-text-left px-5"},[_vm._m(2),_c('p',[_vm._v("Woon je in Niel? Dan komen we bij je langs aan de deur. Woon je elders of wil je zeker zijn van je bestelling, dan kan je hier je vlaaien op voorhand bestellen. Leveren zal gebeuren op 12 oktober tussen 10u en 13u.")]),_c('p',[_c('b',[_vm._v("Levering")]),_vm._v(" is mogelijk in: "),_c('b-taglist',{staticClass:"py-2 mb-0"},[_c('b-tag',{attrs:{"type":"is-primary"}},[_vm._v("Niel")]),_c('b-tag',{attrs:{"type":"is-primary"}},[_vm._v("Schelle")]),_c('b-tag',{attrs:{"type":"is-primary"}},[_vm._v("Hemiksem")]),_c('b-tag',{attrs:{"type":"is-primary"}},[_vm._v("Aartselaar")]),_c('b-tag',{attrs:{"type":"is-primary"}},[_vm._v("Boom")])],1),_vm._v(" Voor grotere bestellingen zijn er meer opties mogelijk, "),_c('a',{attrs:{"href":"mailto:vlaaienslag@scoutshellegat.be?subject=Bestelling op maat"}},[_vm._v("hiervoor neem je best contact met ons op")]),_vm._v(".")],1),_vm._m(3),_c('b-notification',{attrs:{"type":"","aria-close-label":"Close notification"}},[_vm._v(" Bestellen kan tot en met "),_c('b',[_vm._v("donderdag 10 oktober!")])])],1),_c('div',{staticClass:"pretix-widget-compat",attrs:{"event":"https://pretix.eu/scoutshellegat/vlaaienslag2024/","single-item-select":"button"}}),_vm._m(4)])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"the_container pt-5",staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"sliding-background mx-0"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero is-white has-text-centered"},[_c('div',{staticClass:"hero-body pt-5 pb-5"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title is-size-1 has-text-primary"},[_vm._v(" Vlaaienslag ")]),_c('h1',{staticClass:"subtitle has-text-primary"},[_vm._v(" Scouts Hellegat ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Welkom bij de "),_c('b',[_vm._v("jaarlijkse vlaaienslag")]),_vm._v(" van Scouts Hellegat! Op 12 oktober 2024 zullen wij jullie opnieuw voorzien van heerlijke Limburgse Vlaaien.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Na afrekenen krijgt u een mailtje in uw mailbox waarop alle details van uw bestelling terug te vinden zijn. Bij vragen kan je ons steeds contacteren op "),_c('a',{attrs:{"href":"mailto:vlaaienslag@scoutshellegat.be"}},[_vm._v("vlaaienslag@scoutshellegat.be")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('noscript',[_c('div',{staticClass:"pretix-widget"},[_c('div',{staticClass:"pretix-widget-info-message"},[_vm._v(" JavaScript is disabled in your browser. To access our ticket shop without JavaScript, please "),_c('a',{attrs:{"target":"_blank","rel":"noopener","href":"https://pretix.eu/scoutshellegat/vlaaienslag2024/"}},[_vm._v("click here")]),_vm._v(". ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_vm._v(" Een jaarlijkse actie van © "),_c('a',{attrs:{"href":"https://scoutshellegat.be/"}},[_vm._v("Scouts Hellegat")]),_vm._v(" ⚜️ ")])])])
}]

export { render, staticRenderFns }